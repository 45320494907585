import React, { useState, useEffect, useContext } from 'react';
import API from '../../../Services/API';
import Styles from '../mytasks.module.css';
import { EditContext } from "../../../Context/BreadcrumbsContext";
import { Box, Button } from '@chakra-ui/react';

import KeyPerformance from './KeyPerformance/KeyPerformance'
import ProgressNextSteps from './ProgressNextSteps/ProgressNextSteps';
import SupportNeeded from './SupportNeeded/SupportNeeded';

const Qbr = () => {
    const token = window.localStorage.getItem("accessToken");
    const [editable, setEditable] = useState(false);
    const { edit, setEdit } = useContext(EditContext);
    const [profile, setProfile] = useState(null);
    const [commsUserType, setCommsUserType] = useState(false);
    const [actionOfficeUserType, setActionOfficeUserType] = useState(false);
    const [superUserType, setSuperUserType] = useState(false);
    const [activeTab, setActiveTab] = useState('keyperformance');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'keyperformance': return <KeyPerformance />
            case 'progressnextsteps': return <ProgressNextSteps />
            case 'supportneeded': return <SupportNeeded />

            
            default : return null;
        }
    };

    return (
        <>
            <Box>
                <Box>
                    <>
                        <Button
                            onClick={() => setActiveTab('keyperformance')}
                            className={activeTab === 'keyperformance' ? `${Styles.active} ${Styles.tabStyle}` : Styles.tabStyle}
                        >
                            Key Performance
                        </Button>
                        <Button
                            onClick={() => setActiveTab('progressnextsteps')}
                            className={activeTab === 'progressnextsteps' ? `${Styles.active} ${Styles.tabStyle}` : Styles.tabStyle}
                        >
                            Progress Next Steps
                        </Button>
                        <Button
                            onClick={() => setActiveTab('supportneeded')}
                            className={activeTab === 'supportneeded' ? `${Styles.active} ${Styles.tabStyle}` : Styles.tabStyle}
                        >
                            Support Needed
                        </Button>
                    </>
                </Box>
                <Box className={Styles.tabContent}>{renderTabContent()}</Box>
            </Box>
        </>
    )
}

export default Qbr