import React, { useState, useRef } from "react";
import API from "../../../../../../../../../Services/API";
import Select from 'react-select';
import { useParams } from "react-router-dom";
import JoditEditor from 'jodit-react';
import Styles from './editRecord.module.css';
import { SimpleGrid, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, FormControl, FormLabel, Button } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import LoaderSpinner from "../../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import ErrorWidget from "../../../../../../../../Widgets/ErrorWidget/ErrorWidget";

const EditRecord = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { actionid } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [goals, setGoals] = useState(props.goals || '');  // Default empty string if no goals
    const [achievements, setAchievements] = useState(props.achievements || '');  // Default empty string
    const [nextSteps, setNextSteps] = useState(props.nextSteps || '');  // Default empty string
    const [selectedOption, setSelectedOption] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState(null)
    const editorGoal = useRef(null);
    const editorAchievements = useRef(null);
    const editorNextSteps = useRef(null);

    const handleChange = (selected) => {
        setSelectedOption(selected);
    };

    const saveRecord = () =>{
        setIsLoading(true)
        setMessage(null)
        const body = {
            id: props.id,
            approval_status: "Ready to Submit",
            achievements_to_date: document.getElementById('getAchievements').value,
            next_steps: document.getElementById('getNextSteps').value,
            goals: document.getElementById('getGoals').value,
            strategy: selectedOption !== null ? selectedOption.value : props.strategy,
            "module_id":actionid,
        }
        API.put(`/progress-next-steps`, body, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            props.getUpdatedRecords()
            setMessage('Updated step successfully')
            setIsOpen(false)
            setIsLoading(false)
        }).catch(error=>{
            setMessage(
                error.response.data.non_field_errors ? error.response.data.non_field_errors[0] :
                error.response.data.msg ? error.response.data.msg : 'Facing technical error'
            )
            setIsOpen(false)
            setIsLoading(false)
        })
    }

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}

            <IconButton
                minW={'0'}
                minH={'0'}
                height={'25px'}
                w={'25px'}
                variant="solid"
                aria-label="Edit"
                icon={<EditIcon />}
                className={Styles.edit}
                onClick={() => setIsOpen(true)}
            />
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalOverlay />
                <ModalContent maxW="800px">
                    <ModalHeader>Edit Record</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel fontWeight="bold">Select Strategy</FormLabel>
                            <Select
                                options={props.strategiesOptions}
                                value={
                                    selectedOption || 
                                    props.strategiesOptions.find(option => option.label === props.strategyName) || 
                                    null
                                  }
                                onChange={handleChange}
                                placeholder="Select a strategy"
                            />
                        </FormControl>
                        <SimpleGrid columns={3} spacing={1}>
                            <FormControl>
                                <FormLabel fontWeight="bold">Goals</FormLabel>
                                <JoditEditor
                                    ref={editorGoal}
                                    value={goals}
                                    //onChange={(newContent) => setGoals(newContent)}
                                    tabIndex={1}
                                    id={'getGoals'}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight="bold">Achievements to Date</FormLabel>
                                <JoditEditor
                                    ref={editorAchievements}
                                    value={achievements}
                                    //onChange={(newContent) => setAchievements(newContent)}
                                    tabIndex={1}
                                    id={'getAchievements'}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight="bold">Next Steps</FormLabel>
                                <JoditEditor
                                    ref={editorNextSteps}
                                    value={nextSteps}
                                    //onChange={(newContent) => setNextSteps(newContent)}
                                    tabIndex={1}
                                    id={'getNextSteps'}
                                />
                            </FormControl>
                        </SimpleGrid>
                    </ModalBody>
                    <ModalFooter backgroundColor={'transparent'}>
                        <Button backgroundColor={'transparent'} onClick={() => setIsOpen(false)}>Cancel</Button>
                        <Button onClick={saveRecord} colorScheme="blue" ml={3}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default EditRecord;
