import React, { useEffect, useState, useContext } from 'react';
import Styles from '../qbr.module.css'
import { Box, Text, Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import API from '../../../../Services/API';
import { Link } from 'react-router-dom';
import parse from "html-react-parser";
import { EditContext } from '../../../../Context/BreadcrumbsContext';
import { TabContainer } from 'react-bootstrap';
import ApprooveComponent from './ApprooveComponent/ApprooveComponent';
import RejectComponent from './RejectComponent/RejectComponent';

const KeyPerformance = () => {
  const token = window.localStorage.getItem("accessToken")
  const [data, setData] = useState([])
  const { user } = useContext(EditContext);
  const [loading, setLoading] = useState(false);
  
  useEffect(()=>{
    getUpdatedRecords()
  },[])

  const getUpdatedRecords = ()=>{
    API.get(`/my-task-qbr-key-performance`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
    .then(response=>{
        setData(response.data)
    })
    .catch(error=>{
    })
  }

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr bg="gray.100">
              <Th className={Styles.th} color="blue.600">Fiscal Year</Th>
              <Th className={Styles.th} color="blue.600">Metric Name</Th>
              <Th className={Styles.th} color="blue.600">Action</Th>
              <Th className={Styles.th} color="blue.600">Value</Th>
              <Th className={Styles.th} color="blue.600">Approval Status</Th>
              <Th className={Styles.th} color="blue.600">Reject Reason</Th>
              <Th className={Styles.th} color="blue.600">Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              data && data.map(item=>
                <Tr className={Styles.tr}>
                  <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                    {item.fiscal_year_name ? parse(item.fiscal_year_name) : ''}
                  </Td>
                  <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                    {item.metric_name ? parse(item.metric_name) : ''}
                  </Td>
                  <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                    {item.metric_name ? parse(item.action_name) : ''}
                  </Td>
                  <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                      {item.value}
                  </Td>
                  <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                      {item.approval_status}
                  </Td>
                  <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                      <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                        {item.approval_status === "rejected" ? <>{item.reject_reason}</> : null}
                      </Box>
                  </Td>
                  <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                      <ApprooveComponent
                          id={item.id}
                          goals={item.goals} 
                          achievements={item.achievements_to_date} 
                          nextSteps={item.next_steps} 
                          strategy={item.strategy}
                          strategyName={item.strategy_name}
                          action={item.action}
                          //strategiesOptions={strategiesOptions} 
                          getUpdatedRecords={getUpdatedRecords}
                      />
                      <RejectComponent
                          id={item.id}
                          goals={item.goals} 
                          achievements={item.achievements_to_date} 
                          nextSteps={item.next_steps} 
                          strategy={item.strategy}
                          strategyName={item.strategy_name}
                          action={item.action}
                          //strategiesOptions={strategiesOptions} 
                          getUpdatedRecords={getUpdatedRecords}
                      />
                  </Td>
                </Tr>
              )
            }
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}

export default KeyPerformance