import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import API from '../../../../../../Services/API'
import SlideOne from './SlideOne/SlideOne'
import SlideTwo from './SlideTwo/SlideTwo'
import SlideThree from './SlideThree/SlideThree';
import SlideFour from './SlideFour/SlideFour';
import { Box } from '@chakra-ui/react';
import GeneratePdf from './GeneratePdf/GeneratePdf';

import { FiscalYearProvider } from './Context/FiscalYearContext';

const Qbr = () => {
    const token = window.localStorage.getItem("accessToken");
    const { actionid } = useParams();
    const [action, setAction] = useState(null);
    const [selectedFiscalYearID, setSelectedFiscalYearID] = useState(null);
    const [slide2Status, setSlide2Status] = useState('')
    const [slide3Status, setSlide3Status] = useState('')
    const [slide4Status, setSlide4Status] = useState('')

    useEffect(()=>{
        setAction(null);
        setSelectedFiscalYearID(null);
        setSlide2Status('');
        setSlide3Status('');
        setSlide4Status('');
        API.get(`/pillar?module_id=${actionid}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then((response) => {
            setAction(response.data);
            API.get(`/qbr-selected-fy?action_id=${response.data.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => {
                setSelectedFiscalYearID(response?.data[0]?.id)
            }).catch(error=>{
            })
        }).catch((error) => {
        });
    }, [token, actionid])

    const getSlide2Status = (status) =>{
        setSlide2Status(status)
        console.log("setSlide2Status", status)
    }
    const getSlide3Status = (status) =>{
        setSlide3Status(status)
        console.log("setSlide3Status", status)
    }
    const getSlide4Status = (status) =>{
        setSlide4Status(status)
        console.log("setSlide4Status", status)
    }

    return (
        <FiscalYearProvider>
            <Box>
                {slide2Status === 'Approved' && slide3Status === 'Approved' && slide4Status === 'Approved' ? <GeneratePdf /> : null}
                <SlideOne />
                <SlideTwo getSlide2Status={getSlide2Status} selectedFiscalYearID={selectedFiscalYearID} action={action} />
                <SlideThree getSlide3Status={getSlide3Status} selectedFiscalYearID={selectedFiscalYearID} action={action} />
                <SlideFour getSlide4Status={getSlide4Status} selectedFiscalYearID={selectedFiscalYearID} action={action} />
            </Box>
        </FiscalYearProvider>
        
    )
}

export default Qbr