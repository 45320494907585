import React, {useState, useRef} from 'react'
import Styles from './approve.module.css'
import {SimpleGrid, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, FormControl, FormLabel, Input, Text, Button, Switch, Tooltip } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import API from '../../../../../Services/API';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const ApprooveComponent = (props) => {
    const token = window.localStorage.getItem('accessToken');
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState(null);

    const saveRecord = () =>{
        setIsLoading(true)
        setMessage(null)
        const body = {
            id: props.id,
            approval_status: "Approved",
            //"module_id":actionid,
        }
        API.put(`/qbr-key-performance`, body, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            props.getUpdatedRecords()
            setMessage('Approved successfully')
            setIsOpen(false)
            setIsLoading(false)
        }).catch(error=>{
            setMessage(
                error.response.data.non_field_errors ? error.response.data.non_field_errors[0] :
                error.response.data.msg ? error.response.data.msg : 'Facing technical error'
            )
            setIsOpen(false)
            setIsLoading(false)
        })
    }
    

    return (
        <>
            {isLoading && <LoaderSpinner /> }
            {message && <ErrorWidget message={message} /> }
            <Tooltip label="Approve" aria-label="Approve">
                <IconButton
                    variant="solid"
                    aria-label="Edit"
                    icon={<CheckIcon />}
                    className={Styles.edit}
                    onClick={() => setIsOpen(true)}
                />
            </Tooltip>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Approve Record</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text className={Styles.contentInfo} mt={'0px'}>Are you sure you want to Approve it? This action cannot be reversed.</Text>
                    </ModalBody>
                    <ModalFooter backgroundColor={'transparent'}>
                        <Button backgroundColor={'transparent'} onClick={() => setIsOpen(false)}>Cancel</Button>
                        <Button onClick={saveRecord} colorScheme="blue" ml={3}>
                            Approve 
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ApprooveComponent