import React, { useEffect, useState, useContext } from 'react';
import Styles from '../qbr.module.css'
import { Box, Text, Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import API from '../../../../Services/API';
import { Link } from 'react-router-dom';
import parse from "html-react-parser";
import { EditContext } from '../../../../Context/BreadcrumbsContext';
import { TabContainer } from 'react-bootstrap';
import ApprooveComponent from './ApprooveComponent/ApprooveComponent';
import RejectComponent from './RejectComponent/RejectComponent';

const ProgressNextSteps = () => {
    const token = window.localStorage.getItem("accessToken")
    const [data, setData] = useState([])
    const { user } = useContext(EditContext);
    const [loading, setLoading] = useState(false);
      
    useEffect(()=>{
        getUpdatedRecords()
    },[])

    const getUpdatedRecords = ()=>{
        API.get(`/my-task-progress-next-steps`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response=>{
            setData(response.data)
        })
        .catch(error=>{
        })
    }


    return (
        <>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr bg="gray.100">
                            <Th className={Styles.th} color="blue.600">Strategy Name</Th>
                            <Th className={Styles.th} color="blue.600">Goals</Th>
                            <Th className={Styles.th} color="blue.600">Achievements to Date</Th>
                            <Th className={Styles.th} color="blue.600">Next Steps</Th>
                            <Th className={Styles.th} color="blue.600">Approval Status</Th>
                            <Th className={Styles.th} color="blue.600">Reject Reason</Th>
                            <Th className={Styles.th} color="blue.600">Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            data && data.map(item=>
                                <Tr className={Styles.tr}>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            {item.strategy_name ? parse(item.strategy_name) : ''}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            {item.goals ? parse(item.goals) : ''}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            {item.achievements_to_date ? parse(item.achievements_to_date)  :''}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            {item.next_steps ? parse(item.next_steps) : ''}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            {item.approval_status}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            {item.approval_status === "rejected" ? <>{item.reject_reason}</> : null}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} whiteSpace="nowrap">
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            <ApprooveComponent
                                                id={item.id}
                                                goals={item.goals} 
                                                achievements={item.achievements_to_date} 
                                                nextSteps={item.next_steps} 
                                                strategy={item.strategy}
                                                strategyName={item.strategy_name}
                                                action={item.action}
                                                //strategiesOptions={strategiesOptions} 
                                                getUpdatedRecords={getUpdatedRecords}
                                            />
                                            <RejectComponent
                                                id={item.id}
                                                goals={item.goals} 
                                                achievements={item.achievements_to_date} 
                                                nextSteps={item.next_steps} 
                                                strategy={item.strategy}
                                                strategyName={item.strategy_name}
                                                action={item.action}
                                                //strategiesOptions={strategiesOptions} 
                                                getUpdatedRecords={getUpdatedRecords}
                                            />
                                        </Box>
                                    </Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ProgressNextSteps