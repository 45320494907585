import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import { EditContext } from '../../../../../../../../Context/BreadcrumbsContext';
import API from '../../../../../../../../Services/API';
import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import LoaderSpinner from '../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../../Widgets/ErrorWidget/ErrorWidget'
import { FiscalYearContext } from '../../Context/FiscalYearContext';

const FiscalYear = () => {
    const token = window.localStorage.getItem("accessToken");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { actionid } = useParams();
    const {edit} = useContext(EditContext);
    const [isLoading, setIsLoading] = useState(true);
    const [action, setAction] = useState(null);
    const [fiscalYearList, setFiscalYearList] = useState([]);
    const [fiscalQtrList, setFiscalQtrList] = useState([]);
    const [qbrData, setQbrData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [message, setMessage] = useState(null);
    // const [selectedFiscalYearName, setSelectedFiscalYearName] = useState(null);
    const { selectedFiscalYearName, setSelectedFiscalYearName } = useContext(FiscalYearContext);
    const [noData, setNoData]  = useState(false)
    

    useEffect(() => {
        const fetchFiscalData = async () => {
            try {
                setAction(null);
                document.documentElement.scrollTo(0, 0);
                const fiscalResponse = await API.get(`/fiscal-year-detail`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const fiscalYears = fiscalResponse.data?.[0]?.fiscalyear || [];
                const fiscalQuarters = fiscalResponse.data?.[1]?.fiscalquarter || [];
                setFiscalYearList(fiscalYears);
                setFiscalQtrList(fiscalQuarters);
                const pillarResponse = await API.get(`/pillar?module_id=${actionid}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setAction(pillarResponse.data);
                const qbrResponse = await API.get(`/qbr-selected-fy?action_id=${pillarResponse.data.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setQbrData(qbrResponse.data);
                if(qbrResponse.data[0]?.fiscal_year){
                    const selectedFiscalYear = fiscalYears.find(
                        (year) => year.id === qbrResponse.data[0]?.fiscal_year
                    )
                    setSelectedFiscalYearName(selectedFiscalYear?.name || null);
                }else{
                    setNoData(true)
                    setSelectedFiscalYearName(null);
                }
                
                setIsLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsLoading(false)
            }
        };
    
        fetchFiscalData();
    }, [actionid]);
    

    const sortedFiscalYears = fiscalYearList.sort((a, b) => {
        const extractYear = (name) => (name.startsWith("FY") ? parseInt(name.slice(2)) : -Infinity);
        return extractYear(b.name) - extractYear(a.name);
    })

    const fiscalYearOptions = sortedFiscalYears.map((year) => ({
        value: year.id,
        label: year.name,
    }));

    const handleChange = (selected) => {
        setSelectedOption(selected);
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "#ffffff",
            border: "1px solid #63b3ed",
            borderRadius: "8px",
            padding: "5px",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#ffffff", // White placeholder color
            fontSize: "14px",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#ffffff", // Ensures the selected value is also white
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#63b3ed" : state.isFocused ? "#63b3ed" : "#ffffff",
            color: state.isSelected ? "#ffffff" : state.isFocused ? "#ffffff" : "#333333",
            padding: "10px",
            cursor: "pointer",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#f0f8ff",
            borderRadius: "8px",
        }),
    };

    const saveFiscalYear = () => {
        setMessage(null)
        if(selectedOption !== null){
            if(qbrData.length > 0){
                onOpen();
            }else{
                setIsLoading(true)
                const body = {
                    action: action.id,
                    fiscal_year: selectedOption.value,
                    "module_id": actionid,
                }
                API.post(`/qbr-selected-fy`, body, {
                    headers: {
                      'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    setSelectedOption(null);
                    API.get(`/qbr-selected-fy?action_id=${action.id}`,{
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(response=>{
                        setQbrData(response.data)
                        const selectedFiscalYear = fiscalYearList.find(year => year.id === response.data[0].fiscal_year);
                        setSelectedFiscalYearName(selectedFiscalYear && selectedFiscalYear.name)
                    }).catch((error) => {
        
                    })
                    setIsLoading(false)
                    setMessage('Year succesfully created for action review')
                }).catch(error=>{
                    setMessage(error.response.data.msg)
                    setIsLoading(false)
                })
            }
        }else{
            setMessage("Please select fiscalyear")
        }
    }

    const deleteandCreateNew = async () =>{
        setIsLoading(true)
        try {
            const response = await API.delete('/qbr-selected-fy', {
                data: { id: qbrData && qbrData[0].id},
                headers: {
                    'Authorization': 'Bearer ' + token, // Adding Authorization header
                },
            });

            const body = {
                action: action.id,
                fiscal_year: selectedOption.value,
                "module_id": actionid,
            }
            API.post(`/qbr-selected-fy`, body, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                setSelectedOption(null);
                setMessage('Deleted old data and updated new data with selected year')
                API.get(`/qbr-selected-fy?action_id=${action.id}`,{
                    headers: {
                      'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    setQbrData(response.data)
                    const selectedFiscalYear = fiscalYearList.find(year => year.id === response.data[0].fiscal_year);
                    setSelectedFiscalYearName(selectedFiscalYear && selectedFiscalYear.name)
                    setIsLoading(false)
                    window.location.hash = "qbr";
                    window.location.reload();
                }).catch(error=>{
                    setIsLoading(false)
                })
            }).catch(error=>{
                setIsLoading(false)
            })
            onClose();
        } catch (error) {
            setIsLoading(false)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null }
            {message && <ErrorWidget message={message} />}
            <Box style={{display: "flex",alignItems: "center",gap: "10px",}}>
                {edit ? 
                    <>
                        <Select
                            options={fiscalYearOptions}
                            //value={selectedOption}
                            onChange={handleChange}
                            placeholder="Select a Fiscal Year"
                            styles={customStyles}
                            value={
                                selectedOption || 
                                fiscalYearOptions.find(option => option.label === selectedFiscalYearName) || 
                              null
                            }
                        />
                        <Button 
                            borderColor={'#63b3ed'} 
                            color={'#ffffff'}  
                            variant='outline' 
                            _hover={{backgroundColor: "#63b3ed", color: "#ffffff",}} 
                            onClick={saveFiscalYear}
                        > Save </Button>
                    </>: 
                    <Box> 
                        {
                            noData ? 
                            <>
                                <Box float={'left'}>
                                    <Select
                                        options={fiscalYearOptions}
                                        //value={selectedOption}
                                        onChange={handleChange}
                                        placeholder="Select a Fiscal Year"
                                        styles={customStyles}
                                        value={
                                            selectedOption || 
                                            fiscalYearOptions.find(option => option.label === selectedFiscalYearName) || 
                                        null
                                        }
                                    />
                                </Box>
                                <Box float={'left'} mt={'5px'} ml={'5px'}>
                                    <Button 
                                        borderColor={'#63b3ed'} 
                                        color={'#ffffff'}  
                                        variant='outline' 
                                        _hover={{backgroundColor: "#63b3ed", color: "#ffffff",}} 
                                        onClick={saveFiscalYear}
                                    > Save </Button>
                                </Box>
                            </> : <>{selectedFiscalYearName} Action Review </>
                        }
                        
                    </Box> 
                }
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Data Reset Confirmation</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete all your saved data? <br />
                        This action will remove everything and reset to new.
                    </ModalBody>
                    <ModalFooter backgroundColor={'transparent'}>
                        <Button mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button colorScheme="blue" mr={3} onClick={deleteandCreateNew}>
                            Delete and create new
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default FiscalYear