import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import API from "../../../../../../../../../Services/API";
import { Box, Input, FormControl, FormLabel, Button } from "@chakra-ui/react";
import LoaderSpinner from "../../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";

import ErrorWidget from "../../../../../../../../Widgets/ErrorWidget/ErrorWidget";

const AddIndicators = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { actionid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [value, setValue] = useState(props.value);
    const [qbrId, setQbrId] = useState(null);
    const [message, setMessage] = useState(null);
    const handleChange = (event) => {
        setValue(event.target.value);
    }

    useEffect(()=>{
        API.get(`/qbr-key-performance?action_id=${props.id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setQbrId(response.data[0].id)
            API.get(`/qbr-key-performance?id=${response.data[0].id}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(response=>{ 
                console.log(response)
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
        })
    },[])
    
    const handleSubmit = () => {
        setMessage(null)
        setIsLoading(true)
        if(props.value !== null){
            const body = {
                "id": qbrId,
                "value": value,
                approval_status: "Ready to Submit",
                "module_id":actionid,
            };
            
            API.put(`/qbr-key-performance`, body, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                API.get(`/qbr-key-performance?action_id=${props.id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }).then(response => {
                    console.log("Data fetched successfully:", response.data);
                }).catch(error => {
                    console.error("Error fetching data:", error);
                });
                props.getUpdatedData()
                setMessage('Updated value successfully')
                setIsLoading(false)
            }).catch(error => {
                console.error("Error in PUT request:", error.response?.data?.non_field_errors?.[0] || 'Facing technical error');
                setMessage(
                    error.response.data.non_field_errors ? error.response.data.non_field_errors[0] :
                    error.response.data.msg ? error.response.data.msg : 'Facing technical error'
                )
                setIsLoading(false)
            });
        }else{
            const body = {
                "action": props.id,
                "fiscal_year": props.fiscalYear,
                "selectedfy": props.selectedFy,
                "metric": props.metric,
                "value": value,
                approval_status: "Ready to Submit",
                "module_id": actionid,
            }

            API.post(`/qbr-key-performance`, body, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                API.get(`/qbr-key-performance?action_id=${props.id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    
                }).catch(error=>{
    
                })
                props.getUpdatedData()
                setMessage('Added value successfully')
                setIsLoading(false)
            }).catch(error=>{
                console.log("error.response", error.response.data.msg)
                setMessage(
                    error.response.data.non_field_errors ? error.response.data.non_field_errors[0] :
                    error.response.data.msg ? error.response.data.msg : 'Facing technical error'
                )
                
                setIsLoading(false)
            })
        }
    }
    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            <Box p={0} maxW="md" mx="auto">
                <FormControl id="single-value-field" mb={4}>
                    <Input
                        placeholder="Enter a Realized Value..."
                        onChange={handleChange}
                        borderColor={'#63b3ed'} 
                        defaultValue={props.value}
                    />
                </FormControl>
                <Button 
                    borderColor={'#63b3ed'} 
                    color={'#ffffff'}  
                    variant='outline' 
                    _hover={{backgroundColor: "#63b3ed", color: "#ffffff",}}  onClick={handleSubmit}> Save 
                </Button>
            </Box>
        </>
    )
}

export default AddIndicators