import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Styles from "../slideThree.module.css";
import { EditContext } from '../../../../../../../../Context/BreadcrumbsContext';
import API from "../../../../../../../../Services/API";
import { useParams } from "react-router-dom";
import Select from "react-select";
import {Box,Table,TableContainer,Tbody,Td,Th,Thead,Tr,Spinner,Text, position, Button,} from "@chakra-ui/react";
import parse from 'html-react-parser';
import AddNewRecord from "./AddNewRecord/AddNewRecord";
import EditRecord from "./EditRecord/EditRecord";
import DeleteRecord from "./DeleteRecord/DeleteRecord";
import { FiscalYearContext } from '../../Context/FiscalYearContext';

const DataTable = ({ progressData: initialData, selectedFiscalYearID, getUpdate }) => {
  const token = window.localStorage.getItem("accessToken");
  const {edit} = useContext(EditContext);
  const { actionid } = useParams();
  const [action, setAction] = useState(null);
  const [progressNextSteps, setProgressNextSteps] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progressData, setProgressData] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { selectedFiscalYearName } = useContext(FiscalYearContext);

  useEffect(()=>{
    getUpdatedRecords()
  }, [token, actionid])

  const strategiesOptions = strategies.map(strategy => ({
    value: strategy.id,
    label: strategy.name,
  }));

  const handleChange = (selected) => {
    setSelectedOption(selected);
    console.log("Selected option:", selected);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#ffffff",
      border: "1px solid #63b3ed",
      borderRadius: "8px",
      padding: "5px",
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#ffffff", // White placeholder color
        fontSize: "14px",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#ffffff", // Ensures the selected value is also white
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#63b3ed" : state.isFocused ? "#63b3ed" : "#ffffff",
      color: state.isSelected ? "#ffffff" : state.isFocused ? "#ffffff" : "#333333",
      padding: "10px",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#f0f8ff",
      borderRadius: "8px",
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 99999999,
      position: 'absolute'
    }),
  }

  const getUpdatedRecords = () =>{
    API.get(`/pillar?module_id=${actionid}`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((response) => {
      setAction(response.data);
      API.get(`/strategy?action_id=${response.data.id}`, { 
        headers: { 'Authorization': 'Bearer ' + token } 
      })
      .then(response => {
        setStrategies(response.data)
      })
      .catch(error => {
        console.error('Error fetching strategy:', error);
      })
      API.get(`/progress-next-steps?action_id=${response.data.id}`)
      .then(response=>{
        setProgressData(response.data)
        if (response.data.length > 0) {
          let status = null;
        
          const isReadyToSubmit = response.data.some(
            (record) => record.approval_status === "Ready to Submit"
          );
        
          if (isReadyToSubmit) {
            status = "Ready to Submit";
          } else {
            const isApproved = response.data.every(
              (record) => record.approval_status === "Approved"
            );
        
            if (isApproved) {
              status = "Approved";
            }
          }
        
          getUpdate(status);
        }
      }).catch(error=>{
      }) 
    }).catch((error) => {
    });
  }

  return (
    <TableContainer
      border="1px"
      mt="20px"
      mb="20px"
      borderColor="rgb(0 160 218)"
      overflowY={'visible'}
      minH={edit ? '320px' : '1px'}
    >
      {edit ?
        <AddNewRecord 
          strategiesOptions={strategiesOptions} 
          customStyles={customStyles} 
          action={action} 
          selectedFiscalYearID={selectedFiscalYearID}
          getUpdatedRecords={getUpdatedRecords}
        /> : null 
      }
      <Table
        variant="simple"
        size="md"
        border="1px"
        borderColor="gray.200"
        aria-label="Progress Next Steps Table"
        overflowY={'visible'}
      >
        <Thead>
          <Tr>
            <Th className={Styles.th}>Action Strategies</Th>
            <Th className={Styles.th}> {selectedFiscalYearName} Goals</Th>
            <Th className={Styles.th}>Achievements to Date</Th>
            <Th className={Styles.th}>Next Steps</Th>
            {
              edit ? <Th className={Styles.th}>Action</Th> : null
            }
          </Tr>
        </Thead>
        <Tbody>
          {progressData && progressData.length > 0 ? (
            progressData.map((row, index) => (
              <Tr key={index}>
                <Td className={Styles.th}>
                  <Box maxW="300px" whiteSpace="pre-wrap">
                    {
                      edit ?
                        <>
                          <Select
                            options={strategiesOptions}
                            value={
                              selectedOption || 
                              strategiesOptions.find(option => option.label === row.strategy_name) || 
                              null
                            }
                            onChange={handleChange}
                            placeholder="Select a strategy"
                            styles={customStyles}
                          />
                        </>
                      : row.strategy_name || "N/A"
                    }
                  </Box>
                </Td>
                <Td className={Styles.th}>
                  <Box maxW="300px" whiteSpace="pre-wrap">
                    {row.goals != null  ? parse(row.goals) : 'N/A'}
                  </Box>
                </Td>
                <Td className={Styles.th}>
                  <Box maxW="300px" whiteSpace="pre-wrap">
                    {row.achievements_to_date != null  ? parse(row.achievements_to_date) : 'N/A'}
                  </Box>
                </Td>
                <Td className={Styles.th}>
                  <Box maxW="300px" whiteSpace="pre-wrap">
                    {row.next_steps != null  ? parse(row.next_steps) : 'N/A'}
                  </Box>
                </Td>
                {
                  edit ?
                    <Td className={Styles.th}>
                      <EditRecord 
                        id={row.id}
                        goals={row.goals} 
                        achievements={row.achievements_to_date} 
                        nextSteps={row.next_steps} 
                        strategy={row.strategy}
                        strategyName={row.strategy_name}
                        strategiesOptions={strategiesOptions} 
                        getUpdatedRecords={getUpdatedRecords}
                      />
                      <DeleteRecord
                        id={row.id}
                      />
                    </Td>
                  :null
                }
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={4} textAlign="center">
                No data available.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

DataTable.propTypes = {
  progressData: PropTypes.arrayOf(
    PropTypes.shape({
      strategy: PropTypes.string,
      goals: PropTypes.string,
      achievements: PropTypes.arrayOf(PropTypes.string),
      nextSteps: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

DataTable.defaultProps = {
  progressData: [],
};

export default DataTable;
